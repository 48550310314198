<nav
  *ngIf="{
    activeProduct: activeCintraProductService.activeProduct$ | async,
    breakpoints: cssBreakpointsService.breakpoints$ | async
  } as state"
>
  <div
    tid="product-nav"
    class="product-nav {{ state.activeProduct.toLowerCase() + '-nav' }} row g-0 {{
      state.activeProduct === 'AdminHub' ? 'system-purple' : ''
    }}"
  >
    <ng-container *ngIf="!state.breakpoints.isLargeScreen || this.activeSecurity.isEmployeeHubIosUser">
      <div class="col-11 d-flex align-items-center" [ngSwitch]="state.activeProduct">
        <app-employment-menu *ngSwitchCase="'EmployeeHub'" class="d-block"></app-employment-menu>
        <app-bureau-menu *ngSwitchCase="'Bureau'" class="d-block"></app-bureau-menu>
        <app-organisation-menu *ngSwitchDefault class="d-block"></app-organisation-menu>
      </div>
      <div class="col-1 d-flex align-items-center justify-content-end">
        <button id="burger-bar-toggle" tid="burger-toggle" (click)="burgerBarService.toggleBurgerBar()" tabindex="0">
          <mat-icon aria-hidden="false" aria-label="menu icon">{{ menuIcon$ | async }}</mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="state.breakpoints.isLargeScreen && !this.activeSecurity.isEmployeeHubIosUser">
      <div class="col-5">
        <app-bureau-navigation *ngIf="state.activeProduct === 'Bureau'"></app-bureau-navigation>
        <app-cintra-pay-navigation *ngIf="state.activeProduct === 'Payroll'"></app-cintra-pay-navigation>
        <app-employee-hub-navigation *ngIf="state.activeProduct === 'EmployeeHub'"></app-employee-hub-navigation>
        <app-admin-hub-navigation *ngIf="state.activeProduct === 'AdminHub'"></app-admin-hub-navigation>
      </div>
      <div class="col-7 d-flex align-items-center justify-content-end" [ngSwitch]="state.activeProduct">
        <app-employment-menu *ngSwitchCase="'EmployeeHub'"></app-employment-menu>
        <app-bureau-menu *ngSwitchCase="'Bureau'"></app-bureau-menu>
        <app-organisation-menu *ngSwitchDefault></app-organisation-menu>
      </div>
    </ng-container>
  </div>
</nav>
